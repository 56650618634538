import { useEffect, useState } from 'react'

export const useClientSide = () => {
  const [ready, setReady] = useState(false)
  useEffect(() => {
    const timeId = setTimeout(() => setReady(true), 1)
    return () => clearTimeout(timeId)
  }, [])
  return ready
}
